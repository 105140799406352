import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { VStack } from '@adc/parallax-component-library';
import { AxiosError } from 'axios';

import { ApiStatus } from 'Enums';

import { useApiError } from 'Hooks';

import {
  Button,
  Footer,
  Header,
  LogoActionBar,
  Main,
  RichText,
  ScrollContainer,
  Spinner,
} from 'Components/utility';

import { RootState } from 'Reducers/index';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { logout } from 'Utilities/session';

import { handleConsent } from 'Services/consent';

const mapStateToProps = ({ env: { app }, register }: RootState) => {
  return {
    app,
    patientId: register.patientId,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const RWEConsent: React.FC<Props> = ({ app, patientId }) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.UNAUTHORIZED:
          logout();
          break;
        default:
          showApiErrorModal();
      }
    },
    [showApiErrorModal]
  );

  const handleSubmitResearchAction = useCallback(
    async (action: string) => {
      try {
        setLoading(true);

        await handleConsent(patientId, action, app, 'realWorldEvidence');

        mediator.publish('router:navigate', '/login');
      } catch (err) {
        const error = err as AxiosError<ApiErrorData>;

        handleApiRequestError(error);
      } finally {
        setLoading(false);
      }
    },
    [app, handleApiRequestError, patientId]
  );

  return (
    <Main>
      <LogoActionBar testID="Research" title={i18n.t<string>('Research.title')} noActionButton />
      {loading && <Spinner />}
      <ScrollContainer>
        <Header testID="Research" title={i18n.t<string>('Research.title')} />
        <VStack padding="$4">
          <RichText text={i18n.t<string>('Research.content.researchRichText.bodyText')} />
        </VStack>
      </ScrollContainer>
      <Footer
        customProps={
          <VStack padding="$4">
            <Button
              onPress={() => handleSubmitResearchAction('accept')}
              testID="Research.accept-btn"
              label={i18n.t<string>('Global.microcopy.common.accept')}
              aria-label={i18n.t<string>('Global.microcopy.common.accept')}
              marginBottom="$4"
            />
            <Button
              variant="tertiary"
              testID="Research.decline-btn"
              label={i18n.t<string>('Global.microcopy.common.decline')}
              aria-label={i18n.t<string>('Global.microcopy.common.decline')}
              onPress={() => handleSubmitResearchAction('decline')}
            />
          </VStack>
        }
      />
    </Main>
  );
};

export default connector(RWEConsent);
