import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import mediator from 'Utilities/mediator';

const scrollTop = () => {
  mediator.publish('ui:main-wrapper:scroll-top');
};

// Render the passed in step and optionally warp it with a bootstrap card
const wizardStepWrapperProvider: React.FC<any> = (Step, props) => {
  const WizardStepWrapper = ({ withoutWrapper, ...props }: any) => {
    const stepRef = useRef<HTMLDivElement>(null);

    if (withoutWrapper) {
      return (
        <div ref={stepRef}>
          <Step {...props} />
        </div>
      );
    } else {
      return (
        <div className="row" ref={stepRef}>
          <div id="wizard-card" className="w-100">
            <Step {...props} />
          </div>
        </div>
      );
    }
  };

  return <WizardStepWrapper {...props} />;
};

/**
 * This will manage drawing a wizard for us in the LV world.
 * It will handle rendering the title, the subTitle (which includes the steps indicator),
 * and the card that renders each step inside of it.
 * It will also manage the next and back actions as well as
 * keep track of all the values created by each step.
 *
 * Each step component will receive as props from the Wizard:
 *
 * the props that the step definition has
 * - back (a function to handle pressing back)
 * - next (function to handle pressing next, your component should use this when it wants to pass on the values)
 * - values (the current set of values so far collected in the wizard)
 * - lastStep (a flag to let the step know that it's the last one in the wizard)
 *
 * @param {*} [initialValues] Values to use initially before the wizard even begins
 * @param {boolean} [noHeader] Flag that controls whether you want to hide the header and subheader
 * @param {*[]} steps List of steps which are represented by objects with a 'component' property which is the
 *    component to render as the step. Optionally, it can have a 'props' property as well with props
 *    that should additionally be passed into the step. In particular, if you pass in withoutWrapper then
 *    your component will not be automatically rendered into a card and instead your component will have to be
 *    in charge of rendering that.
 * @param {string} [subTitle] Text to render as the sub title of the wizard
 * @param {string} [title] Text to render as the title of the wizard
 */

export type NavigationProps<T> = {
  back: (data?: T) => void;
  next: (data: T) => void;
  values: T;
  currentStep: number;
};

const Wizard: React.FC<any> = ({ initialValues, currentStep, steps, ...props }) => {
  const [currentState, setCurrentState] = useState({
    currentStep: currentStep ?? 0,
    values: initialValues,
  });

  useEffect(() => {
    setCurrentState((currState) => {
      const newState = { ...currState };

      if (initialValues) {
        newState.values = initialValues;
      }

      if (currentStep) {
        newState.currentStep = currentStep;
      }

      return newState;
    });
  }, [initialValues, currentStep]);

  const back = useCallback(
    (values?: any) => {
      if (currentState.currentStep === 0) {
        mediator.publish('router:back');
      } else {
        scrollTop();
        setCurrentState((currState) => {
          const newState = { ...currState };

          if (currState.currentStep > 0) {
            newState.currentStep -= 1;
          }

          if (values) {
            newState.values = values;
          }

          return newState;
        });
      }
    },
    [currentState.currentStep]
  );

  const next = useCallback(
    (values: any) => {
      scrollTop();
      setCurrentState((currState) => {
        const newState = { ...currState };

        if (values) {
          newState.values = values;
        }

        // check the call is from the current step, to prevent it from being called
        // by the same step more than once
        if (steps[currState.currentStep] && currState.currentStep < steps.length - 1) {
          newState.currentStep += 1;
        }

        return newState;
      });
    },
    [steps]
  );

  // Setup navigation blocking while we are inside the wizard
  useEffect(() => {
    let canceled = false;
    const { currentStep } = currentState;

    const _setUpBlockNavigation = () => {
      mediator.publish('router:blockNavigation', (_: string, isBack: boolean) => {
        if (canceled) {
          return false;
        }

        if (isBack && currentStep === 0) {
          return false;
        } else if (isBack) {
          back();
        } else {
          return false;
        }

        return true;
      });
    };

    _setUpBlockNavigation();

    return () => {
      canceled = true;
    };
  }, [back, currentState]);

  const step = wizardStepWrapperProvider(steps[currentState.currentStep]?.component, {
    ...steps[currentState.currentStep]?.props,
    ...props,
    back,
    key: currentState.currentStep,
    lastStep: currentState.currentStep === steps.length - 1,
    next,
    values: currentState.values,
    currentStep: currentState.currentStep,
  });

  return <React.Fragment>{step}</React.Fragment>;
};

export default memo(Wizard);
