import React, { useCallback } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { Box, Image } from 'native-base';

import { Footer, Header, LogoActionBar, Main, ScrollContainer } from 'Components/utility';
import { NavigationProps } from 'Components/wizard';

import i18n from 'Utilities/i18n';

type Props = NavigationProps<Register>;

const RegisterMinorIntro: React.FC<Props> = ({ next, back, values }) => {
  const handleSubmit = useCallback(() => {
    next(values);
  }, [next, values]);

  const handleOnBack = useCallback(() => {
    back();
  }, [back]);

  return (
    <Main>
      <LogoActionBar
        testID="RegisterMinorIntro"
        title={i18n.t<string>('CreateAccountIntro.title')}
        onBack={handleOnBack}
      />
      <ScrollContainer>
        <Header testID="RegisterMinorIntro" title={i18n.t<string>('CreateAccountIntro.title')} />
        <Box alignItems="center" px={4} mt={1}>
          <Image
            size="container"
            resizeMode="contain"
            width="100vw"
            height="260px"
            nativeID="CreateAccountIntro.image-of-family"
            mx="auto"
            source={{
              uri: i18n.t<string>('CreateAccountIntro.content.createAccountIntro.media.introMinor'),
            }}
            alt="a family with the father hugging the mom and the daughter in the middle of them"
            my="3"
            mb="5"
          />
          <Text
            nativeID="CreateAccountIntro.content.createAccountIntro.primaryText"
            color="text.100"
            fontSize="l"
            fontWeight="bodyLargeMedium"
            fontFamily="bodyLargeMedium"
            lineHeight="2xl"
          >
            {i18n.t<string>('CreateAccountIntro.content.createAccountIntro.primaryText')}
          </Text>
          <Text
            mt={4}
            nativeID="CreateAccountIntro.content.createAccountIntro.bodyText"
            color="text.40"
            fontSize="l"
            fontWeight="bodyBaseDefault"
            fontFamily="bodyBaseDefault"
            lineHeight="3xl"
          >
            {i18n.t<string>('CreateAccountIntro.content.createAccountIntro.bodyText', {
              appName: i18n.t<string>('Global.appNames.libreView'),
            })}
          </Text>
        </Box>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Global.microcopy.common.continue')}
        onButtonSubmit={handleSubmit}
        testIDButton="RegisterMinorIntro.continue-btn"
        isButtonDisabled={false}
      />
    </Main>
  );
};

export default RegisterMinorIntro;
