import React, { memo, useCallback, useRef } from 'react';

import { IconLinkArrow12x24 } from '@adc/parallax-icons';

import { IconButton, LegacyInput } from 'Components/utility';

type DateInputProps = {
  isInvalid?: boolean;
  label: string;
  errorMessage?: string;
  placeholder?: string;
  testID?: string;
  value?: string;
  setFieldTouched: any;
  onClick: () => void;
  onBlur: any;
  disabled: boolean;
};

const DateInput = memo(
  ({
    isInvalid = false,
    label = '',
    errorMessage,
    placeholder = '',
    testID = 'dateOfBirth',
    value,
    setFieldTouched,
    onClick,
    onBlur,
    disabled,
  }: DateInputProps) => {
    const inputRef = useRef<HTMLInputElement>();

    const onEventStart = useCallback(() => {
      setFieldTouched(testID);
      onClick();

      //  Hack to force the keyboard to close on iOS
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.blur();
      }
    }, [onClick, setFieldTouched, testID]);

    return (
      <LegacyInput
        ref={inputRef}
        placeholder={placeholder}
        label={label}
        isInvalid={isInvalid}
        errorMessage={errorMessage}
        keyboardType="default"
        nativeID={testID}
        editable={false}
        focusable={false}
        value={value}
        onTouchEnd={onEventStart}
        isDisabled={disabled}
        onBlur={onBlur}
        InputRightElement={
          <IconButton marginRight="$4" style={{ cursor: 'pointer' }} onPress={onEventStart}>
            <IconLinkArrow12x24
              testID={`${testID}-arrow-btn`}
              color="$interactive.tertiary.enabled.text"
            />
          </IconButton>
        }
      />
    );
  }
);

DateInput.displayName = 'DateInput';

export { DateInput };
