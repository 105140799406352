import { CONFIG_UPDATE, ConfigActions, ConfigState } from './types';

const initialState: ConfigState = {
  adultAges: {},
  countries: [],
  documents: {
    hipaadoc: {},
    pnpat: {},
    toufsll: {},
    toupat: {},
    hipaa: {},
  },
  documentsUrl: '',
  languages: [],
  libreSharingApiUrl: '',
  partners: {},
  supportedCountries: [],
  validation: {
    identity: [],
    libreSharingApi: {
      patient: {
        firstName: {
          stringMaxLength: 0,
        },
        lastName: {
          stringMaxLength: 0,
        },
      },
    },
  },
  webviewsUrl: '',
  zipCodePatterns: {},
};

export const configReducer = (state = initialState, action: ConfigActions): ConfigState => {
  switch (action.type) {
    case CONFIG_UPDATE:
      return {
        ...state,
        ...action.config,
      };
    default:
      return state;
  }
};
