import React, { memo, useEffect, useRef, useState } from 'react';

import { LoadingSpinner, Text, useTheme } from '@adc/parallax-component-library';

import { Button } from 'Components/utility';

import { Container } from './styles';
import useDatePicker from './useDatePicker';

type Props = {
  onSave: (dateString: string, resetToDate: (date: string) => void) => void;
  onCancel?: () => void;
  isVisible: boolean;
  title: string;
  initialDate?: string;
};

const DatePicker: React.FC<Props> = ({ onSave, onCancel, isVisible, title, initialDate }) => {
  const theme = useTheme();

  const [isDelayedVisible, setIsDelayedVisible] = useState(false);

  const elemRefYear = useRef<HTMLDivElement>(null);
  const elemRefMonth = useRef<HTMLDivElement>(null);
  const elemRefDay = useRef<HTMLDivElement>(null);

  const { dateString, elems, config, selectInitialDate, loading } = useDatePicker(
    elemRefYear,
    elemRefMonth,
    elemRefDay,
    isVisible,
    initialDate
  );

  const resetToDate = (date: string) => {
    selectInitialDate(elems, config, date);
  };

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsDelayedVisible(true);
      }, 200);

      return () => clearTimeout(timer);
    } else {
      setIsDelayedVisible(false);
    }
  }, [isVisible]);

  return (
    <Container
      backgroundColor={theme['surface.containerSecondary'].val}
      neutral50={theme['neutral.50'].val}
      text100={theme['text.100'].val}
      selectorBorderColor={theme['text.100'].val}
    >
      <div
        className="date-selector-container"
        style={{
          left: isDelayedVisible ? 0 : -window.screen.width,
        }}
      >
        {loading && (
          <div className="spinner">
            <LoadingSpinner testID="DatePicker.spinner" />
          </div>
        )}
        <div className="date-selector-header" style={{ opacity: loading ? 0 : 1 }}>
          <Button
            variant="tertiary"
            onPress={() => onCancel && onCancel()}
            testID="DatePicker.btnCancel"
            aria-label=""
            label=""
          />
          <Text
            fontSize="$bodyBase.medium"
            fontWeight="$bodyBase.medium"
            color="$text.100"
            testID="DatePicker.title"
            marginTop="$3"
          >
            {title}
          </Text>
          <Button
            variant="tertiary"
            onPress={() => {
              if (dateString) {
                onSave(dateString, resetToDate);
              }
            }}
            testID="DatePicker.btnDone"
            aria-label="Done"
            label="Done"
          />
        </div>
        <div id="DatePicker.dateSelector" className="date-selector">
          <div id="DatePicker.selector" className="selector" />
          <div className="month" id="DatePicker.month" ref={elemRefMonth} />
          <div className="day" id="DatePicker.day" ref={elemRefDay} />
          <div className="year" id="DatePicker.year" ref={elemRefYear} />
        </div>
      </div>
    </Container>
  );
};

export default memo(DatePicker);
