import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { useTheme } from '@adc/parallax-component-library';
import axios from 'axios';
import styled, { css } from 'styled-components';

import { Header, LogoActionBar, Main, ScrollContainer } from 'Components/utility';

import { RootState } from 'Reducers/index';

import { getDocumentPathFromLanguage } from 'Utilities/dataHelpers';
import i18n from 'Utilities/i18n';
import log from 'Utilities/log';

const mapStateToProps = ({ config, env: { phoneLanguage }, nav: { query } }: RootState) => {
  return {
    documents: {
      toufsll: config.documents?.toufsll,
      pnpat: config.documents?.pnpat,
    },
    phoneLanguage,
    isAccountProfile: query.accountProfile === 'true' ? true : false,
  };
};

const connector = connect(mapStateToProps);

type NavigationProps = {
  type: 'pnpat' | 'toufsll';
};

type Props = ConnectedProps<typeof connector> & NavigationProps;

type DocumentViewProps = {
  textTitle: string;
  text100: string;
  neutralBackground: string;
};

const DocumentView = styled.div<DocumentViewProps>`
  ${(props) => css`
    background-color: ${props.neutralBackground};

    padding: 0 18px 0 18px;

    h1 {
      color: ${props.textTitle};
      margin: 0;
      font-size: 28px;
    }
    h2 {
      color: ${props.textTitle};
      font-size: 18px;
    }
    h3 {
      color: ${props.textTitle};
    }
    p {
      color: ${props.text100};
      font-size: 18px;
    }
    table {
      color: ${props.text100};
    }
    li {
      color: ${props.text100};
      font-size: 18px;
    }
    a:link {
      color: ${props.textTitle};
    }
    a:visited {
      color: ${props.textTitle};
    }
    strong {
      color: ${props.text100};
      font-size: 18px;
    }
    td,
    th {
      text-align: left;
      padding-right: 32px;
    }
    table {
      display: block;
      overflow-x: auto;
    }
  `}
`;

const Document: React.FC<Props> = ({ documents, type, phoneLanguage, isAccountProfile }) => {
  const [documentContentHtml, setDocumentContentHtml] = useState('');

  const theme = useTheme();

  const neutralBackground = theme['surface.background'].val;
  const text100 = theme['text.100'].val;

  useEffect(() => {
    const mountDocument = async () => {
      try {
        const documentPath = getDocumentPathFromLanguage(documents[type], phoneLanguage);

        const { data: documentHtml } = await axios.get<string>(documentPath);

        const bodyElement = /<body.*?>([\s\S]*)<\/body>/.exec(documentHtml);

        if (bodyElement && bodyElement.length) {
          const docBodyElement = bodyElement[0];

          setDocumentContentHtml(docBodyElement.concat('</br>'));
        }
      } catch (error) {
        log.error(error);
      }
    };

    mountDocument();
  }, [documents, phoneLanguage, type]);

  return (
    <Main>
      <LogoActionBar
        testID="Document"
        title={
          type === 'toufsll'
            ? i18n.t<string>('Global.microcopy.common.termsOfUse')
            : i18n.t<string>('Global.microcopy.common.privacyNotice')
        }
      />
      <ScrollContainer>
        <Header
          testID="Document"
          title={
            type === 'toufsll'
              ? i18n.t<string>('Global.microcopy.common.termsOfUse')
              : i18n.t<string>('Global.microcopy.common.privacyNotice')
          }
        />
        <DocumentView
          text100={text100}
          textTitle={text100}
          neutralBackground={neutralBackground}
          dangerouslySetInnerHTML={{ __html: documentContentHtml }}
          style={{
            marginTop: isAccountProfile ? 55 : 0,
          }}
        />
      </ScrollContainer>
    </Main>
  );
};

export default connector(Document);
