import React from 'react';
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { ConnectedProps, connect } from 'react-redux';

import { HStack, Stack, Text, VStack } from '@adc/parallax-component-library';
import { IconBack } from '@adc/parallax-icons';

import { IconButton } from 'Components/utility';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';

import { RootState } from 'src/reducers';

import { ActionBarHeader } from './ActionBarHeader';

type LogoActionBarProps = {
  testID?: string;
  title?: string;
  onBack?: () => void;
  noActionButton?: boolean;
};

const mapStateToProps = ({ scrollPage }: RootState) => {
  return {
    scrollPage,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & LogoActionBarProps;

const LogoActionBar: React.FC<Props> = ({ testID, scrollPage, title, onBack, noActionButton }) => {
  const logoAnimatedStyles = useAnimatedStyle(() => {
    if (!scrollPage.isActive) {
      return {
        opacity: 1,
      };
    }

    const opacity = interpolate(scrollPage.scrollValue, [0, 20], [1, 0], Extrapolation.CLAMP);

    return {
      opacity,
    };
  });

  const titleAnimatedStyles = useAnimatedStyle(() => {
    if (!scrollPage.isActive) {
      return {
        opacity: 0,
      };
    }

    const opacity = interpolate(scrollPage.scrollValue, [0, 20], [0, 1], Extrapolation.CLAMP);

    return {
      opacity,
    };
  });

  return (
    <VStack
      style={{
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 2,
      }}
      backgroundColor="$surface.container"
    >
      <ActionBarHeader testID={testID} />
      <HStack padding="$4" justifyContent="center" alignItems="center">
        {!noActionButton && (
          <IconButton
            marginLeft="$4"
            style={{ position: 'absolute', zIndex: 2, left: 0, cursor: 'pointer' }}
            onPress={() => (onBack ? onBack() : mediator.publish('router:back'))}
          >
            <IconBack />
          </IconButton>
        )}
        <Animated.View style={logoAnimatedStyles}>
          <img src={i18n.t('Global.microcopy.common.libreLogo')} alt="" />
        </Animated.View>
        {title && (
          <Animated.View
            style={[
              {
                position: 'absolute',
                zIndex: 1,
                width: '100%',
              },
              titleAnimatedStyles,
            ]}
          >
            <Text
              testID={`${testID}.action-title`}
              color="$text.100"
              fontSize="$h4.strong"
              fontWeight="$h4.strong"
              alignSelf="center"
              textAlign="center"
              marginHorizontal="$5"
              maxWidth="70%"
            >
              {title}
            </Text>
          </Animated.View>
        )}
        <Stack />
      </HStack>
    </VStack>
  );
};

const ConnectedLogoActionBar = connector(LogoActionBar);

export { ConnectedLogoActionBar as LogoActionBar };
