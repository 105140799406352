import jwtDecode from 'jwt-decode';

import { urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { persistAuthSession } from 'Utilities/session';

export const signIn = (email: string, password: string, app: string, cid: string) => {
  return new Promise<AuthSession>((resolve, reject) => {
    mediator
      .request<ApiResponse<AuthResponse>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'login',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          email,
          password,
          consents: [
            {
              id: 'touLibre',
              action: 'accept',
            },
            {
              id: 'pp',
              action: 'accept',
            },
          ],
        },
      })
      .then((result) => {
        if (result.data) {
          const decodedToken = jwtDecode<{ id: string; did: string; exp: number; iat: number }>(
            result.data.access_token
          );

          const authSession: AuthSession = {
            ...result.data,
            account_id: decodedToken.id,
            expires: decodedToken.exp,
            cid,
            app,
          };

          persistAuthSession(authSession);

          resolve(authSession);
          return;
        }

        reject(result);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const forgotPass = (email: string, app: string, pId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'password/forgot',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          email,
          partnerID: pId,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const resetPass = (password: string, tokenID?: string, app?: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'password/reset',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          password,
          tokenID,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
