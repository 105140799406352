import React, { memo } from 'react';

import { HStack, Text } from '@adc/parallax-component-library';
import { IconValidationInvalid } from '@adc/parallax-icons';

type InputErrorFieldProps = {
  errorMessage?: string;
  testID?: string;
};

const InputErrorField = memo(({ testID, errorMessage }: InputErrorFieldProps) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <HStack marginBottom="$2">
      <IconValidationInvalid color="$support.danger.surfaceOnColorAlt" testID={`${testID}-icon`} />
      <Text
        display="flex"
        testID={`${testID}-errorMessage`}
        color="$support.danger.surfaceOnColorAlt"
        fontSize="$bodySmall.default"
        marginLeft="$2"
      >
        {errorMessage}
      </Text>
    </HStack>
  );
});

InputErrorField.displayName = 'InputErrorField';

export { InputErrorField };
