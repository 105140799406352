import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { useApiError, useAuth } from 'Hooks';

import { LogoActionBar, Main, Spinner } from 'Components/utility';

import { RootState } from 'Reducers/index';

import { getAuthSession } from 'Utilities/session';

import { createConnection } from 'Services/insulet';

const mapStateToProps = ({ env: { redirect_uri } }: RootState) => {
  return {
    redirect_uri,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const Connection: React.FC<Props> = ({ redirect_uri }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { showErrorPage } = useApiError();

  const { redirect } = useAuth();

  useEffect(() => {
    const initializeConnection = async () => {
      try {
        setIsLoading(true);

        const authSession = getAuthSession();

        if (authSession) {
          const redirectUrl = await createConnection(
            authSession.cid,
            redirect_uri,
            authSession.account_id
          );

          redirect(redirectUrl);

          return;
        }

        showErrorPage();
      } catch (err) {
        showErrorPage();
      } finally {
        setIsLoading(false);
      }
    };

    initializeConnection();
  }, [redirect, redirect_uri, showErrorPage]);

  return (
    <Main>
      <LogoActionBar testID="Connection" />
      {isLoading && <Spinner testID="Connection" />}
    </Main>
  );
};

export default connector(Connection);
