import React, { useCallback } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { RegisterProps } from 'Components/register/CreateNewUser';
import { Footer, Form, Header, LogoActionBar, Main, ScrollContainer } from 'Components/utility';
import { Input } from 'Components/utility/Input';
import { NavigationProps } from 'Components/wizard';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';

type Props = NavigationProps<Register> & RegisterProps;

const RegisterMinorPersonalInfo: React.FC<Props> = ({
  next,
  back,
  validateNameLength,
  values: currentValues,
}) => {
  const validationSchema = Yup.object().shape({
    guardianFirstName: Yup.string()
      .required(
        i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentFirstName.helpText.required'
        )
      )
      .trim(),
    guardianLastName: Yup.string()
      .required(
        i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentLastName.helpText.required'
        )
      )
      .trim(),
  });

  const onSubmit = useCallback(
    async (values: Register) => {
      try {
        if (!values.guardianFirstName || !values.guardianLastName) {
          return;
        }

        const isValid = validateNameLength(values.guardianFirstName, values.guardianLastName);

        if (!isValid) {
          return;
        }

        const register: Register = {
          ...currentValues,
          ...values,
        };

        next(register);
      } catch (err) {
        log.error(err);
      }
    },
    [currentValues, next, validateNameLength]
  );

  const { handleSubmit, handleChange, handleBlur, isValid, errors, touched, values } = useFormik({
    initialValues: {
      ...currentValues,
    },
    validationSchema: validationSchema,
    onSubmit,
    validateOnBlur: true,
  });

  const handleOnBack = useCallback(() => {
    back(values);
  }, [back, values]);

  return (
    <Main>
      <LogoActionBar
        testID="RegisterMinorPersonalInfo"
        title={i18n.t<string>('CreateAccountPersonalInfo.title')}
        onBack={handleOnBack}
      />
      <ScrollContainer>
        <Header
          testID="RegisterMinorPersonalInfo"
          title={i18n.t<string>('CreateAccountPersonalInfo.title')}
          subtitle={i18n.t<string>(
            'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.primaryText'
          )}
        />
        <Form>
          <Input
            aria-label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentFirstName.label'
            )}
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentFirstName.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentFirstName.label'
            )}
            testID="guardianFirstName"
            inputMode="text"
            errorMessage={errors.guardianFirstName}
            isInvalid={errors.guardianFirstName && touched.guardianFirstName ? true : false}
            value={values.guardianFirstName}
            onChangeText={handleChange('guardianFirstName')}
            onBlur={handleBlur('guardianFirstName')}
          />
          <Input
            aria-label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentLastName.label'
            )}
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentLastName.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentLastName.label'
            )}
            testID="guardianLastName"
            inputMode="text"
            errorMessage={errors.guardianLastName}
            isInvalid={errors.guardianLastName && touched.guardianLastName ? true : false}
            value={values.guardianLastName}
            onChangeText={handleChange('guardianLastName')}
            onBlur={handleBlur('guardianLastName')}
          />
          <Text
            nativeID="CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.secondaryText"
            fontFamily="headlineBaseMedium"
            fontWeight="headlineBaseMedium"
            fontSize="l"
            color="text.20"
            pt="5"
            textTransform="uppercase"
          >
            {i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.secondaryText'
            )}
          </Text>
          <Input
            aria-label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.label'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.label'
            )}
            testID="firstName"
            inputMode="text"
            value={values.firstName}
            disabled={true}
          />
          <Input
            aria-label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.label'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.label'
            )}
            testID="lastName"
            inputMode="text"
            value={values.lastName}
            disabled={true}
          />
          <Input
            aria-label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.label'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.label'
            )}
            testID="dateOfBirth"
            inputMode="text"
            value={values.dateOfBirth ? format(parseISO(values.dateOfBirth), 'MM/dd/yyyy') : ''}
            disabled={true}
          />
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.links.next.label'
        )}
        onButtonSubmit={handleSubmit}
        testIDButton="CreateAccountPersonalInfo.next-btn"
        isButtonDisabled={!values.guardianFirstName || !values.guardianLastName || !isValid}
      />
    </Main>
  );
};

export default RegisterMinorPersonalInfo;
