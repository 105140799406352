export const REGISTER = 'REGISTER';
export const REGISTER_CLEAR = 'REGISTER_CLEAR';

export type RegisterState = Register;

export interface RegisterAction {
  type: typeof REGISTER;
  register: Register;
}

export interface RegisterClearAction {
  type: typeof REGISTER_CLEAR;
}

export type RegisterActions = RegisterAction | RegisterClearAction;
