import React, { useCallback, useMemo, useState } from 'react';

import { Checkbox, HStack } from '@adc/parallax-component-library';

import { RegisterProps } from 'Components/register/CreateNewUser';
import {
  CountryBottomSheetModal,
  Footer,
  Form,
  Header,
  LogoActionBar,
  Main,
  RichText,
  ScrollContainer,
} from 'Components/utility';
import { SelectorInput } from 'Components/utility/SelectorInput';
import { NavigationProps } from 'Components/wizard';

import { REGISTER_CLEAR } from 'Reducers/register/types';

import i18n from 'Utilities/i18n';
import { store } from 'Utilities/store';

type Props = NavigationProps<Register> & RegisterProps;

const RegisterConfirmCountry: React.FC<Props> = ({ next, back, values }) => {
  const [country, setCountry] = useState(values.country);
  const [confirmCountryChecked, setConfirmCountryChecked] = useState(false);
  const [isBottomSheetModalOpen, setIsBottomSheetModalOpen] = useState(false);

  const onSubmit = useCallback(() => {
    const register: Register = {
      ...values,
      country,
    };

    next(register);
  }, [country, next, values]);

  const onCancelBottomSheetModal = useCallback(() => {
    setIsBottomSheetModalOpen(false);
  }, []);

  const onSelectCountry = useCallback((selectedCountry: string) => {
    setCountry(selectedCountry);
    setIsBottomSheetModalOpen(false);
  }, []);

  const handleOnBack = useCallback(() => {
    store.dispatch({ type: REGISTER_CLEAR });
    back();
  }, [back]);

  const selectorValue = useMemo(() => {
    return country ? i18n.t<string>(`Global.microcopy.countries.${country}`) : '';
  }, [country]);

  return (
    <Main>
      <LogoActionBar
        testID="ConfirmCountry"
        title={i18n.t('ConfirmCountry.title')}
        onBack={handleOnBack}
      />
      <ScrollContainer>
        <Header
          testID="ConfirmCountry"
          title={i18n.t<string>('ConfirmCountry.title')}
          subtitle={i18n.t<string>('ConfirmCountry.subtitle')}
        />
        <Form>
          <SelectorInput
            testID="ConfirmCountry"
            label={i18n.t('ConfirmCountry.content.selectCountry.primaryText')}
            aria-label={i18n.t('ConfirmCountry.content.selectCountry.primaryText')}
            value={selectorValue}
            onClick={() => setIsBottomSheetModalOpen(true)}
          />
          <HStack padding="$4">
            <Checkbox
              checked={confirmCountryChecked}
              onChange={() => setConfirmCountryChecked(!confirmCountryChecked)}
              marginRight="$4"
              testID="ConfirmCountry.checkBox"
            />
            <RichText
              testID="ConfirmCountry.selectCountry-bodyText"
              text={i18n.t<string>('ConfirmCountry.content.selectCountry.bodyText')}
            />
          </HStack>
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Global.microcopy.common.next')}
        onButtonSubmit={onSubmit}
        testIDButton="ConfirmCountry.next-btn"
        isButtonDisabled={!confirmCountryChecked || !country}
      />
      <CountryBottomSheetModal
        isOpen={isBottomSheetModalOpen}
        onCancel={onCancelBottomSheetModal}
        onDone={onSelectCountry}
        country={country}
      />
    </Main>
  );
};

export default RegisterConfirmCountry;
