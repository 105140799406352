import React, { useCallback, useEffect, useState } from 'react';

import { Button, Checkbox, HStack, Text, VStack } from '@adc/parallax-component-library';
import 'native-base';

import {
  Footer,
  Form,
  Header,
  LogoActionBar,
  Main,
  RichText,
  ScrollContainer,
} from 'Components/utility';
import { NavigationProps } from 'Components/wizard';

import { SHOW_MODAL } from 'Reducers/modal/types';
import { REGISTER } from 'Reducers/register/types';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

import { RegisterProps } from '../CreateNewUser';

type Props = NavigationProps<Register> & RegisterProps;

const RegisterLegalNotices: React.FC<Props> = ({ next, back, values, currentStep }) => {
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false);
  const [privacyNoticeChecked, setPrivacyNoticeChecked] = useState(false);

  useEffect(() => {
    const touLibre = values.consents.find((consent) => consent.id === 'touLibre');
    const pp = values.consents.find((consent) => consent.id === 'pp');

    setTermsOfUseChecked(touLibre?.action === 'accept');
    setPrivacyNoticeChecked(pp?.action === 'accept');
  }, [values]);

  const handleLinkClick = (href: string) => {
    store.dispatch({
      type: REGISTER,
      register: {
        ...values,
        currentStep,
        consents: [
          {
            id: 'touLibre',
            action: termsOfUseChecked ? 'accept' : 'decline',
          },
          {
            id: 'pp',
            action: privacyNoticeChecked ? 'accept' : 'decline',
          },
        ],
      },
    });
    mediator.publish('router:navigate', href);
  };

  const showAcceptTermsError = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        message: {
          msgKey: 'LegalNotices.modals.tapCheckbox.body',
        },
      },
    });
  }, []);

  const onSubmit = useCallback(
    (declined?: boolean) => {
      if (!termsOfUseChecked || !privacyNoticeChecked || declined) {
        showAcceptTermsError();
        return;
      }

      const register: Register = {
        ...values,
        consents: [
          {
            id: 'touLibre',
            action: 'accept',
          },
          {
            id: 'pp',
            action: 'accept',
          },
        ],
      };

      next(register);
    },
    [next, privacyNoticeChecked, showAcceptTermsError, termsOfUseChecked, values]
  );

  return (
    <Main>
      <LogoActionBar
        testID="RegisterLegalNotices"
        title={i18n.t('LegalNotices.title')}
        onBack={back}
      />
      <ScrollContainer>
        <Header testID="RegisterLegalNotices" title={i18n.t<string>('LegalNotices.title')} />
        <Form>
          <RichText
            onPressLink={handleLinkClick}
            text={i18n.t<string>('LegalNotices.content.legalNoticeCopy.bodyText')}
          />
          <HStack padding="$4" marginTop="$4" backgroundColor="$surface.container">
            <Checkbox
              checked={termsOfUseChecked}
              onChange={() => setTermsOfUseChecked(!termsOfUseChecked)}
              marginRight="$4"
              marginTop="$1"
            />
            <VStack>
              <Text
                testID="RegisterLegalNotices.acceptTermsOfUse-primaryText"
                fontSize="$bodyBase.default"
                fontWeight="$bodyBase.default"
                color="$text.100"
              >
                {i18n.t('LegalNotices.content.acceptTermsOfUse.primaryText')}
              </Text>
              <Text
                testID="RegisterLegalNotices.acceptTermsOfUse-secondaryText"
                fontSize="$bodySmall.default"
                fontWeight="$bodySmall.default"
                color="$text.60"
              >
                {i18n.t('LegalNotices.content.acceptTermsOfUse.secondaryText')}
              </Text>
            </VStack>
          </HStack>
          <HStack padding="$4" marginTop="$4" backgroundColor="$surface.container">
            <Checkbox
              checked={privacyNoticeChecked}
              onChange={() => setPrivacyNoticeChecked(!privacyNoticeChecked)}
              marginRight="$4"
              marginTop="$1"
            />
            <VStack>
              <Text
                testID="RegisterLegalNotices.acceptPrivacyNotice-primaryText"
                fontSize="$bodyBase.default"
                fontWeight="$bodyBase.default"
                color="$text.100"
              >
                {i18n.t('LegalNotices.content.acceptPrivacyNotice.primaryText')}
              </Text>
              <Text
                testID="RegisterLegalNotices.acceptPrivacyNotice-secondaryText"
                fontSize="$bodySmall.default"
                fontWeight="$bodySmall.default"
                color="$text.60"
              >
                {i18n.t('LegalNotices.content.acceptPrivacyNotice.secondaryText')}
              </Text>
            </VStack>
          </HStack>
        </Form>
      </ScrollContainer>
      <Footer
        customProps={
          <VStack padding="$4">
            <Button
              onPress={() => onSubmit()}
              testID="RegisterLegalNotices.accept-btn"
              label={i18n.t<string>('Global.microcopy.common.accept')}
              aria-label={i18n.t<string>('Global.microcopy.common.accept')}
              marginBottom="$4"
            />
            <Button
              variant="tertiary"
              testID="RegisterLegalNotices.decline-btn"
              label={i18n.t<string>('Global.microcopy.common.decline')}
              aria-label={i18n.t<string>('Global.microcopy.common.decline')}
              onPress={() => onSubmit(true)}
            />
          </VStack>
        }
      />
    </Main>
  );
};

export default RegisterLegalNotices;
