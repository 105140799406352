import React, { useEffect } from 'react';

import { Text, VStack } from '@adc/parallax-component-library';

import { InsuletStatusCode } from 'Enums';

import { useAuth, usePartner } from 'Hooks';

import { Main } from 'Components/utility/Main';

import i18n from 'Utilities/i18n';
import { store } from 'Utilities/store';

import { LogoActionBar } from '../ActionBar';

const ErrorPage: React.FC = () => {
  const { partner } = usePartner();
  const { redirect } = useAuth();

  useEffect(() => {
    const redirectUrl = store.getState().env.redirect_uri;

    setTimeout(() => {
      redirect(`${redirectUrl}?errorcode=${InsuletStatusCode.INTERNAL_ERROR}`);
    }, 5000); // 5s
  }, [partner, redirect]);

  return (
    <Main>
      <LogoActionBar testID="ErrorPage" />
      <VStack
        padding="$4"
        backgroundColor="$surface.background"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        zIndex={1}
        style={{
          height: '100vh',
          width: '100vw',
        }}
      >
        <Text
          testID="ErrorPage.errorLoading-title"
          marginBottom="$2"
          color="$text.100"
          fontWeight="$h3.strong"
          fontSize="$h3.strong"
        >
          {i18n.t('Global.modals.errorLoading.title')}
        </Text>
        <Text
          testID="ErrorPage.errorLoading-body"
          color="$text.100"
          fontWeight="$bodyBase.default"
          fontSize="$bodyBase.default"
        >
          {i18n.t('Global.modals.errorLoading.body', { partnerName: partner.displayName })}
        </Text>
      </VStack>
    </Main>
  );
};

export default ErrorPage;
