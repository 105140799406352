import React, { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { BottomSheetModal } from '@adc-polaris-component-library/component-library';
import { Divider, RadioListItem, Stack } from '@adc/parallax-component-library';

import { Button } from 'Components/utility';

import i18n from 'Utilities/i18n';

import { RootState } from 'src/reducers';

type CountryBottomSheetModalProps = {
  isOpen: boolean;
  country: string;
  onCancel: () => void;
  onDone: (selectedCountry: string) => void;
};

const mapStateToProps = ({ config, scrollPage: { mainSize } }: RootState) => {
  return {
    supportedCountries: config.supportedCountries,
    mainSize,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & CountryBottomSheetModalProps;

const CountryBottomSheetModal: React.FC<Props> = ({
  isOpen,
  onCancel,
  onDone,
  supportedCountries,
  mainSize,
  country,
}) => {
  const [selectedItem, setSelectedItem] = useState(country);

  return (
    <BottomSheetModal
      isOpen={isOpen}
      headerTitle={i18n.t('ConfirmCountry.content.selectCountry.primaryText')}
      nativeID="CountryBottomSheetModal"
      titleColorToken="text.title"
      leftActionButton={
        <Button
          variant="tertiary"
          testID="CountryBottomSheetModal.cancel-btn"
          label={i18n.t<string>('Global.microcopy.common.cancel')}
          aria-label={i18n.t<string>('Global.microcopy.common.cancel')}
          onPress={onCancel}
        />
      }
      rightActionButton={
        <Button
          variant="tertiary"
          testID="CountryBottomSheetModal.done-btn"
          label={'Done'}
          aria-label={'Done'}
          onPress={() => {
            if (selectedItem) {
              onDone(selectedItem);
            }
          }}
        />
      }
    >
      <div
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: mainSize / 2,
        }}
      >
        {supportedCountries.map((country) => {
          if (i18n.exists(`Global.microcopy.countries.${country}`)) {
            return (
              <Stack key={country}>
                <RadioListItem
                  key={country}
                  title={i18n.t(`Global.microcopy.countries.${country}`)}
                  aria-label={i18n.t(`Global.microcopy.countries.${country}`)}
                  disabled={false}
                  selected={selectedItem === country}
                  onPress={() => setSelectedItem(country)}
                />
                <Divider marginTop="$2" borderColor="$neutral.40" />
              </Stack>
            );
          }
        })}
      </div>
    </BottomSheetModal>
  );
};

const ConnectedCountryBottomSheetModal = connector(CountryBottomSheetModal);

export { ConnectedCountryBottomSheetModal as CountryBottomSheetModal };
