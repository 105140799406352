import { REGISTER, REGISTER_CLEAR, RegisterActions, RegisterState } from './types';

const initialState: RegisterState = {
  patientId: '',
  country: '',
  language: 'en-US',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  password: '',
  confirmPassword: '',
  guardianFirstName: '',
  guardianLastName: '',
  consents: [
    {
      id: 'touLibre',
      action: 'decline',
    },
    {
      id: 'pp',
      action: 'decline',
    },
  ],
  isMinor: false,
  currentStep: 0,
};

export const registerReducer = (state = initialState, action: RegisterActions): RegisterState => {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        ...action.register,
      };
    case REGISTER_CLEAR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
